import React from "react";
import "../css/component/about_mbtcs.scss";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";

const AboutMBTCs = () => {
  // 모바일, 테블릿일 때만 슬라이드
  const isMobile = useMediaQuery({ minWidth: 280, maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1138 });

  let slidesToShow = 3; // 기본 값 (데스크톱 사이즈)

  if (isMobile) {
    slidesToShow = 1;
  } else if (isTablet) {
    slidesToShow = 2;
  }

  const sliderSettings = {
    arrows: false,
    dots: true,
    slidesToShow: slidesToShow,
  };

  return (
    <div className="about_mbtcs">
      <div className="about_mbtcs_bg">
        <div className="title">
          <h2>About MBTCs</h2>
          <p>
            <FormattedMessage id="About_Title01" />
            <br />
            <FormattedMessage id="About_Title02" />
          </p>
        </div>
      </div>

      <div>
        {/* 모바일 일 때만 슬라이드 */}
        {isMobile || isTablet ? (
          <Slider {...sliderSettings}>
            <div className="about_content01 about_content">
              <img
                src={process.env.PUBLIC_URL + "/assets/about_content01.png"}
                alt="Sell NFT miners anytime"
              />
              <strong>
                <FormattedMessage id="about_content_Title01" />
              </strong>
              <p>
                <FormattedMessage id="about_content01" />
              </p>
            </div>
            <div className="about_content02 about_content">
              <img
                src={process.env.PUBLIC_URL + "/assets/about_content02.png"}
                alt="21 million can be mined"
              />
              <strong>
                <FormattedMessage id="about_content_Title02" />
              </strong>
              <p>
                <FormattedMessage id="about_content02" />
              </p>
            </div>
            <div className="about_content03 about_content">
              <img
                src={process.env.PUBLIC_URL + "/assets/about_content03.png"}
                alt="Mineable for about 100 years"
              />
              <strong>
                <FormattedMessage id="about_content_Title03" />
              </strong>
              <p>
                <FormattedMessage id="about_content03" />
              </p>
            </div>
          </Slider>
        ) : (
          <div className="about_content_wrap">
            <div className="about_content01 about_content">
              <img
                src={process.env.PUBLIC_URL + "/assets/about_content01.png"}
                alt="Sell NFT miners anytime"
              />
              <strong>
                <FormattedMessage id="about_content_Title01" />
              </strong>
              <p>
                <FormattedMessage id="about_content01" />
              </p>
            </div>
            <div className="about_content02 about_content">
              <img
                src={process.env.PUBLIC_URL + "/assets/about_content02.png"}
                alt="21 million can be mined"
              />
              <strong>
                <FormattedMessage id="about_content_Title02" />
              </strong>
              <p>
                <FormattedMessage id="about_content02" />
              </p>
            </div>
            <div className="about_content03 about_content">
              <img
                src={process.env.PUBLIC_URL + "/assets/about_content03.png"}
                alt="Mineable for about 100 years"
              />
              <strong>
                <FormattedMessage id="about_content_Title03" />
              </strong>
              <p>
                <FormattedMessage id="about_content03" />
              </p>
            </div>
          </div>
        )}
      </div>

      <div className="about_desc">
        <div className="about_desc_text_wrap inner">
          <div className="about_desc_text01 about_desc_text">
            <img
              src={process.env.PUBLIC_URL + "/assets/about_number01.png"}
              alt="Number 01"
            />
            <strong>
              <FormattedMessage id="about_desc_Title01" />
            </strong>
            <p className="last_p">
              <FormattedMessage id="about_desc01" />
            </p>
          </div>
        </div>
        <div className="about_desc_text_wrap inner">
          <div className="about_desc_text01 about_desc_text">
            <img
              src={process.env.PUBLIC_URL + "/assets/about_number02.png"}
              alt="Number 02"
            />
            <strong>
              <FormattedMessage id="about_desc_Title02" />
            </strong>
            <p className="last_p">
              <FormattedMessage id="about_desc02" />
            </p>
          </div>
        </div>
        <div className="about_desc_text_wrap inner">
          <div className="about_desc_text01 about_desc_text">
            <img
              src={process.env.PUBLIC_URL + "/assets/about_number03.png"}
              alt="Number 03"
            />
            <strong>
              <FormattedMessage id="about_desc_Title03" />
            </strong>
            <p>
              <FormattedMessage id="about_desc03" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMBTCs;
