import { useEffect, useRef, useState } from "react";
import React from "react";
import Slide from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../css/component/news.scss";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const News = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 관리
  const [modalContent, setModalContent] = useState({}); // 모달에 표시할 콘텐츠 저장

  // 페이지 로드 시 모달 자동 열기 설정
  useEffect(() => {
    const defaultModalContent = {
      title: " MBTCs Upcoming Highlights",
      content: "MBTCs Upcoming Highlights",
      date: "",
    };
    setModalContent(defaultModalContent); // 기본 모달 콘텐츠 설정
    setIsModalOpen(true); // 모달 열기
    document.body.classList.add("no-scroll"); // 스크롤 막기
  }, []);

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide every 3 seconds
    afterChange: (idx) => setSNum(idx),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const s = useRef(null);
  const [sNum, setSNum] = useState();
  const [windowSize, setWindowSize] = useState([
    window.screen.width,
    window.screen.height,
  ]);
  useEffect(() => {
    setSNum(0);
    const handleWindowResize = () => {
      setWindowSize([window.screen.width, window.screen.height]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // 뉴스 추가 할 때 여기에 데이터 넣으세요.
  const newsData = [
    {
      image: "/assets/news1.jpg",
      title: "Commencement of NFT Distribution",
      content: "NFT distribution completed",
      date: "from December 22 to 24.",
      url: "",
    },
    {
      image: "/assets/news2.jpg",
      title: "NFT Distribution Schedule",
      content: "NFT distribution set to commence",
      date: "from January 22.",
      url: "",
    },
    {
      image: "/assets/news3.jpg",
      title: "2024 Crypto Expo Dubai",
      content: "Visit & Learn about the Dubai MBTCs Mena Project",
      date: "",
      url: "",
    },
    {
      image: "/assets/news4.jpg",
      title: "2024 Crypto Expo Dubai_On-site",
      content:
        "Special session of MBTCs Project for Participants, exhibitors, and local businesses in Dubai",
      date: "",
      url: "",
    },
    {
      image: "/assets/news5.jpg",
      title: "2024 Crypto Expo Dubai_On-site",
      content:
        "Interviews with local media in Dubai during MBTCs promotion and introduction at the booth",
      date: "",
      url: "",
    },
    {
      image: "/assets/news6.jpg",
      title: "MBTCs Mena Project and Listing",
      content: "Notice on the Progress of the MBTCs Project and Listing",
      date: "",
      url: "",
    },
    {
      image: "/assets/news7.jpg",
      title: "Hello :) this is MBTCs.",
      content:
        "Our Dapp is currently undergoing maintenance for a version update. Thank you for your understanding.",
      date: "",
      url: "",
    },
    {
      image: "/assets/news8.jpg",
      title:
        "Dubai Chocolate, first on MBTC Shopping Mall! Exclusive global community discounts!",
      content:
        "Experience our premium chocolate product, prepared for the global market.",
      date: "",
      url: "",
    },
    {
      image: "/assets/news9.jpg",
      title: " MBTCs Upcoming Highlights",
      content: "MBTCs Upcoming Highlights",
      date: "",
      url: "",
    },
    {
      image: "/assets/news10.jpg",
      title:
        "Renowned real estate firms partner to bring managed asset benefits to the MBTC community.",
      content:
        "Experience our premium chocolate product, prepared for the global market.",
      date: "",
      url: "",
    },
  ];

  const [isPrevActive, setIsPrevActive] = useState(false);
  const [isNextActive, setIsNextActive] = useState(true);

  const handlePrevClick = () => {
    s.current.slickPrev();
    setIsPrevActive(true);
    setIsNextActive(false);
  };

  const handleNextClick = () => {
    s.current.slickNext();
    setIsPrevActive(false);
    setIsNextActive(true);
  };

  const Aalert = () => {
    alert("Comming Soon"); // 경고창을 띄워 준비중임을 알림
  };

  // Learn More 버튼 클릭 시 모달을 여는 함수
  const openModal = (newsItem) => {
    setModalContent(newsItem); // 클릭한 뉴스 항목의 내용을 모달에 설정
    setIsModalOpen(true); // 모달 열림
    document.body.classList.add("no-scroll"); // body에 no-scroll 클래스 추가하여 스크롤 막기
  };

  // 모달 닫기 함수
  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫힘
    document.body.classList.remove("no-scroll"); // body에서 no-scroll 클래스 제거하여 스크롤 해제
  };

  return (
    <div className="news">
      <div className="inner">
        <div className="title">
          <h2>News</h2>
          <p>
            <FormattedMessage id="News_Title01" />
            <br />
            <FormattedMessage id="News_Title02" />
          </p>
        </div>
      </div>

      <Slide {...sliderSettings} ref={s}>
        {newsData.map((item, index) => (
          <div key={"news_" + index} className="news_itm">
            <figure>
              <img src={process.env.PUBLIC_URL + item.image} alt={item.title} />
            </figure>
            <div className="news_itm_text">
              <strong>
                {item.title.length > 120
                  ? item.title.substring(0, 120) + " ..."
                  : item.title}
              </strong>
              <span>
                {item.content.length > 120
                  ? item.content.substring(0, 120) + " ..."
                  : item.content}
              </span>
              <span>
                {item.date.length > 120
                  ? item.content.substring(0, 120) + " ..."
                  : item.date}
              </span>
              <span className="small-btn" onClick={() => openModal(item)}>
                Learn More{" "}
                <span className="details-btn" href="/#">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </span>
            </div>
          </div>
        ))}
      </Slide>

      {/* 모달 추가 */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-window" onClick={(e) => e.stopPropagation()}>
            <span className="modal-close-btn" onClick={closeModal}>
              <svg
                id="Layer_1"
                class="svg-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 49.99"
              >
                <path
                  class="cls-1"
                  d="m25.01,49.99c-6.69,0-12.97-2.6-17.68-7.31C2.6,37.95,0,31.67,0,25S2.6,12.04,7.32,7.31c9.75-9.75,25.61-9.75,35.36,0,9.75,9.75,9.75,25.61,0,35.36-4.71,4.71-11,7.31-17.68,7.31ZM25.01,1c-6.15,0-12.29,2.34-16.98,7.02C3.5,12.56,1,18.58,1,25s2.5,12.44,7.03,16.97c4.53,4.53,10.55,7.02,16.98,7.02s12.45-2.49,16.98-7.02c9.36-9.36,9.36-24.59,0-33.95C37.3,3.34,31.15,1,25.01,1Zm6.98,32.65c-.52,0-1-.2-1.37-.56l-5.49-5.48-5.49,5.48c-.74.73-2.02.73-2.74,0-.37-.37-.57-.85-.57-1.37,0-.52.2-1,.57-1.37l5.48-5.48-5.48-5.48c-.76-.75-.76-1.98,0-2.74.75-.76,1.98-.76,2.74,0l5.49,5.48,5.49-5.48c.76-.75,1.99-.75,2.74,0,.75.76.75,1.98,0,2.74l-5.48,5.48,5.48,5.48c.37.37.57.85.57,1.37,0,.52-.2,1-.57,1.37-.37.37-.85.57-1.37.57Zm-6.86-7.46l6.19,6.19c.35.35.97.35,1.33,0,.18-.18.27-.41.27-.66s-.1-.48-.27-.66l-6.19-6.18,6.19-6.18c.36-.36.36-.96,0-1.33-.36-.36-.96-.36-1.33,0l-6.19,6.19-6.19-6.19c-.37-.37-.96-.36-1.33,0-.36.37-.36.96,0,1.33l6.19,6.18-6.19,6.18c-.18.18-.27.41-.27.66,0,.25.1.49.27.66.35.35.97.35,1.33,0l6.19-6.18Z"
                />
              </svg>
            </span>
            <div className="modal-inner">
              <strong className="modal-title">NOTICE</strong>
              <h2>
                MBTCs <em> NEWS #1</em>
              </h2>
              <blockquote className="styled-blockquote">
                <div className="quote-icon">
                  <svg
                    width="32"
                    height="20"
                    viewBox="0 0 32 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8125 0V12.7812H6.40625V19.1875H0V0H12.8125ZM32 0H19.1875V19.1875H25.5938V12.7812H32V0Z"
                      fill="#0CD680"
                    />
                  </svg>
                </div>
                <p> Upcoming Highlights</p>
              </blockquote>
              <div className="check">
                <span className="check-icon">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="22" height="22" rx="11" fill="#0CCA79" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      stroke="black"
                      stroke-opacity="0.07"
                    />
                    <path
                      d="M9.37891 14.8438L6.13672 11.6016C5.94141 11.4062 5.94141 11.0742 6.13672 10.8789L6.83984 10.1758C7.03516 9.98047 7.34766 9.98047 7.54297 10.1758L9.75 12.3633L14.4375 7.67578C14.6328 7.48047 14.9453 7.48047 15.1406 7.67578L15.8438 8.37891C16.0391 8.57422 16.0391 8.90625 15.8438 9.10156L10.1016 14.8438C9.90625 15.0391 9.57422 15.0391 9.37891 14.8438Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Preparing to Distribute Dubai Chocolate to the Global Market.
              </div>
              <p>
                Preparations are underway to distribute Dubai chocolates to the
                global market. You can purchase and experience these Dubai
                chocolates, specially crafted in the MBTCs edition, through
                MBTCs.
              </p>
              <div className="check">
                <span className="check-icon">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="22" height="22" rx="11" fill="#0CCA79" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      stroke="black"
                      stroke-opacity="0.07"
                    />
                    <path
                      d="M9.37891 14.8438L6.13672 11.6016C5.94141 11.4062 5.94141 11.0742 6.13672 10.8789L6.83984 10.1758C7.03516 9.98047 7.34766 9.98047 7.54297 10.1758L9.75 12.3633L14.4375 7.67578C14.6328 7.48047 14.9453 7.48047 15.1406 7.67578L15.8438 8.37891C16.0391 8.57422 16.0391 8.90625 15.8438 9.10156L10.1016 14.8438C9.90625 15.0391 9.57422 15.0391 9.37891 14.8438Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                MBTCs Asset Launch Preparation
              </div>
              <p>
                We are preparing to launch a platform that allows individual
                investors to easily invest in products like global real estate,
                artworks, and mining, which are typically risky and difficult to
                fully own and trade.
                <br /> This platform will lower the barriers to investing in
                physical assets, allowing a diverse range of people worldwide to
                participate through MBTCs.
              </p>
              <div className="check">
                <span className="check-icon">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="22" height="22" rx="11" fill="#0CCA79" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      stroke="black"
                      stroke-opacity="0.07"
                    />
                    <path
                      d="M9.37891 14.8438L6.13672 11.6016C5.94141 11.4062 5.94141 11.0742 6.13672 10.8789L6.83984 10.1758C7.03516 9.98047 7.34766 9.98047 7.54297 10.1758L9.75 12.3633L14.4375 7.67578C14.6328 7.48047 14.9453 7.48047 15.1406 7.67578L15.8438 8.37891C16.0391 8.57422 16.0391 8.90625 15.8438 9.10156L10.1016 14.8438C9.90625 15.0391 9.57422 15.0391 9.37891 14.8438Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Collaboration Announcements
              </div>
              <blockquote className="styled-blockquote">
                <div className="quote-icon">
                  <svg
                    width="32"
                    height="20"
                    viewBox="0 0 32 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8125 0V12.7812H6.40625V19.1875H0V0H12.8125ZM32 0H19.1875V19.1875H25.5938V12.7812H32V0Z"
                      fill="#0CD680"
                    />
                  </svg>
                </div>
                <p>Collaboration with BLOCKLOGICA</p>
              </blockquote>
              <p>
                MBTCs is expanding its global influence through a new
                collaboration with BLOCKLOGICA. This partnership will enable us
                to reach more diverse areas through collaborations with famous
                artists and influencers, as well as increased community
                activities across various channels.
              </p>
              <div className="check">
                <span className="check-icon">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="22" height="22" rx="11" fill="#0CCA79" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      stroke="black"
                      stroke-opacity="0.07"
                    />
                    <path
                      d="M9.37891 14.8438L6.13672 11.6016C5.94141 11.4062 5.94141 11.0742 6.13672 10.8789L6.83984 10.1758C7.03516 9.98047 7.34766 9.98047 7.54297 10.1758L9.75 12.3633L14.4375 7.67578C14.6328 7.48047 14.9453 7.48047 15.1406 7.67578L15.8438 8.37891C16.0391 8.57422 16.0391 8.90625 15.8438 9.10156L10.1016 14.8438C9.90625 15.0391 9.57422 15.0391 9.37891 14.8438Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                DApp Updates
              </div>
              <blockquote className="styled-blockquote">
                <div className="quote-icon">
                  <svg
                    width="32"
                    height="20"
                    viewBox="0 0 32 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8125 0V12.7812H6.40625V19.1875H0V0H12.8125ZM32 0H19.1875V19.1875H25.5938V12.7812H32V0Z"
                      fill="#0CD680"
                    />
                  </svg>
                </div>
                <p>July 27, 2024 MBTCs NFT Market Opened</p>
              </blockquote>
              <p>
                The MBTCs NFT Market has newly opened. Users can now purchase
                NFTs directly through the NFT DApp by linking their wallets,
                allowing even more users to participate in MBTCs NFT mining.
                <br /> The NFT Market will soon offer member-to-member
                transactions through MBTCs, so please stay tuned.
              </p>
              <div className="check">
                <span className="check-icon">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="22" height="22" rx="11" fill="#0CCA79" />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="21"
                      height="21"
                      rx="10.5"
                      stroke="black"
                      stroke-opacity="0.07"
                    />
                    <path
                      d="M9.37891 14.8438L6.13672 11.6016C5.94141 11.4062 5.94141 11.0742 6.13672 10.8789L6.83984 10.1758C7.03516 9.98047 7.34766 9.98047 7.54297 10.1758L9.75 12.3633L14.4375 7.67578C14.6328 7.48047 14.9453 7.48047 15.1406 7.67578L15.8438 8.37891C16.0391 8.57422 16.0391 8.90625 15.8438 9.10156L10.1016 14.8438C9.90625 15.0391 9.57422 15.0391 9.37891 14.8438Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                M-Fuel Purchase Available
              </div>
              <p>
                M-Fuel is now available for purchase on the MBTCs DApp. As it
                can be directly purchased with BSC-USDT, we look forward to your
                interest and participation.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* 뉴스 기사 생기면 이거 쓰세요 */}
      {/* <Slide {...sliderSettings} ref={s}>
        {newsData.map((item, index) => (
          <a href={item.url} target="_blank" key={index}>
            <div className="news_itm">
              <figure>
                <img src={process.env.PUBLIC_URL + item.image} alt={item.title} />
              </figure>
              <div className="news_itm_text">
                <strong>{item.title.length > 40 ? item.title.substring(0, 40) + ' ...' : item.title}</strong>
                <span>{item.date}</span>
              </div>
            </div>
          </a>
        ))}
      </Slide> */}

      {/* <div className="arrows" style={{visibility: (windowSize[0] < 480) ? 'visible' : 'hidden'}}> */}
      <div className="arrows">
        <button
          onClick={handlePrevClick}
          className={`prev btn ${isPrevActive ? "active" : ""}`}
        >
          <CgChevronLeft />
        </button>
        <button
          onClick={handleNextClick}
          className={`next btn ${isNextActive ? "active" : ""}`}
        >
          <CgChevronRight />
        </button>
      </div>
    </div>
  );
};

export default News;
