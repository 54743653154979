import React from "react"
import "../css/component/partners.scss"

// 번역할 메시지를 가져온다
import { FormattedMessage } from "react-intl"
import LogoPaper from "../componets/LogoPaper"
import { Grid } from "@mui/material"

const Partners = () => {
  return (
    <div className="partners">
      <div className="inner">
        <div className="title">
          <h2>Partners & Backers</h2>
          <p>
            <FormattedMessage id="Partners_Title" />
          </p>
        </div>
        <Grid container md={12} xd={12} spacing={0} alignItems="center">
          <LogoPaper>
            <img src={`/assets/partner/f&c.png`} alt="f&c" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/hqmena.jpg`} alt="hqmena" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/cryptoexpo.png`} alt="cryptoexpo" />
          </LogoPaper>
          {/* <LogoPaper>
            <img
              src={`/assets/partner/petcare_lab_logo_B.png`}
              alt="PetCareLab"
            />
          </LogoPaper> */}
          <LogoPaper>
            <img src={`/assets/partner/agss.jpg`} alt="AGSS" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/mbtcmall.jpg`} alt="mbtcmall" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/mbtcsasset.jpg`} alt="mbtcsasset" />
          </LogoPaper>
          <LogoPaper>
            <img
              src={`/assets/partner/digitalassetsoasis.jpg`}
              alt="digitalassetsoasis"
            />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/knowlex.jpg`} alt="knowlex" />
          </LogoPaper>
          <LogoPaper>
            <img
              src={`/assets/partner/UniversalTechExpoPhilippines.jpg`}
              alt="UniversalTechExpoPhilippines"
            />
          </LogoPaper>
          <LogoPaper>
            <img
              src={`/assets/partner/blocklogica.png`}
              alt="blocklogicaLabs"
            />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/ARADA.jpg`} alt="ARADA" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/AIDAPPS.png`} alt="AIDAPPS" />
          </LogoPaper>
          <LogoPaper>
            <img src={`/assets/partner/sobha.png`} alt="sobha" />
          </LogoPaper>
        </Grid>
      </div>
    </div>
  )
}

export default Partners
